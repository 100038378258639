.class-badge {
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 25px;
    padding: 4px 8px;
    white-space: nowrap;
    font-weight: 600;
    text-transform: capitalize;
    text-align: center;
    font-size: 12px;
}