.booking-card {
  padding: 32px;
  position: relative;
  margin-bottom: 25px;
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid #d3d3d3;
}

.card-heading {
  margin: 0;
  font-size: 20px;
}

.card-main {
  min-height: 90px;
  position: relative;
  padding-top: 10px;
}

.card-main p {
  margin: 0;
}

.card-box {
  padding: 10px 0;
  position: relative;
  border-bottom: 1px solid #eeeeee;
}

.card-box:last-child {
  border-bottom: 0;
}

.card-small-heading {
  margin: 8px 0;
}

.card-inner-heading {
  margin-bottom: 8px;
}

.card-inner-heading .heading {
  font-size: 14px;
  font-weight: 400;
  color: #898989;
}

.card-inner-heading .text {
  font-weight: 500;
}

.card-inner-heading .with-icon {
  display: flex;
}

.card-inner-heading .with-icon svg {
  fill: #898989;
}

.card-inner-heading .with-icon span {
  display: block;
  margin-right: 10px;
}

.card-status {
  text-align: right;
}

.card-inner-price {
  display: flex;
  align-items: center;
  margin: 6px 0;
  justify-content: space-between;
}

.card-inner-price-heading {
  margin-top: 0;
}

.card-inner-price-amount {
  margin-top: 0;
  font-weight: bold;
}

.card-inner-price-options {
  padding: 6px 12px;
  color: #279bdd;
  font-size: 14px;
  background-color: #f5f7ff;
}

.driver-img {
  top: 15px;
  right: 15px;
  position: absolute;
}

.driver-img img {
  object-fit: cover;
}

.card-rating-icon {
  display: flex;
  justify-content: center;
  font-weight: 600;
}

.map-card {
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}
