.detailsBox {
	transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	overflow: hidden;
	box-shadow: rgb(145 158 171 / 24%) 0px 0px 2px 0px,
		rgb(145 158 171 / 24%) 0px 16px 32px -4px;
	border-radius: 16px;
	position: relative;
	z-index: 0;
	padding: 20px;
}
.heading {
	font-size: 20px;
	color: #1e2731;
	margin-top: 0;
	margin-bottom: 15px;
}


.fieldBox {
	margin-bottom: 10px;
}

.fieldBox h3 {
	margin-bottom: 0;
}

.fieldBox p {
	margin: 0;
}

.fieldBox label {
	font-weight: 200;
}
